<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- 标题 -->
        <div>
          <span class="title">{{nav[0].name}}</span>
        </div>
        <!-- 左侧非地图部分 -->
        <div class="content-main-left">
          <!-- 标段输入项 -->
          <div class="content-main-hw" style="padding-top:20px">
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              :inline="true"
              label-position="right"
              label-width="170px"
              size="mini"
            >
              <el-form-item label="环保垃圾屋名称" prop="ecoName" style="margin-bottom: 0px">
                <el-input v-model="form.ecoName" placeholder="请输入" maxlength="20"></el-input>
              </el-form-item>
              <el-form-item label="所属标段" prop="bidId">
                <ayl-cascader-for-bid v-model="form.bidId" @change="handleBidChage"></ayl-cascader-for-bid>
              </el-form-item>
              <el-form-item label="类型">
                <el-select v-model="form.ecoType" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.ecoType.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="用途">
                <el-select v-model="form.application" placeholder="请选择" filterable>
                  <el-option
                    v-for="(item, idx) in $enums_hw.application.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="管理配员(名)">
                <el-input v-model="form.demandWorker" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="垃圾压缩箱数量(个)">
                <el-input v-model="form.garbageCompactorNum" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="交付时间" class="deliveryTime">
                <el-date-picker
                  v-model="form.deliveryTime"
                  type="date"
                  value-format="timestamp"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="产权所有" prop="propertyRights">
                <el-select
                  v-model="form.propertyRights"
                  placeholder="请选择"
                  filterable
                >
                  <el-option
                    v-for="(item, idx) in $enums_hw.propertyRights.list"
                    :key="idx"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="是否通水">
                <el-checkbox v-model="form.waterReady">是</el-checkbox>
              </el-form-item>
              <el-form-item label="是否通电">
                <el-checkbox v-model="form.electricityReady">是</el-checkbox>
              </el-form-item>
              <el-form-item label="排污情况" class="sewageSituation">
                <el-input v-model="form.sewageSituation" placeholder="请输入" maxlength="7"></el-input>
              </el-form-item>
              <el-form-item label="地址" class="ecoAddress">
                <el-input v-model="form.ecoAddress" placeholder="请输入" maxlength="50"></el-input>
              </el-form-item>
              <!-- 环保垃圾屋附件-->
              <el-form-item label="环保垃圾屋附件" style="width: 100%">
                <ayl-upload-single-image v-model="form.ecoImageUrls"></ayl-upload-single-image>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 地图部分 -->
        <ayl-map v-model="mapData" icon="ljhbw"></ayl-map>
      </div>

      <div class="tac mt40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">保存</el-button>
        <el-button class="btn-hw" @click="onSubmitAndNext">保存并录入下一条</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/mixins/add";
export default {
  mixins: [add],

  data() {
    return {
      nav: [{ name: "新增环保垃圾屋" }],
      mapData: {//地图数据
        lnglat: null,//经纬度，数组，形如[118,36]
        scope: null,//标段范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
      }, 
      form: {
        bidId: null, //关联标段编号
        bidMaintainUnit: null, //养护企业
        // application:0,//用途
        deliveryTime: null, //交付时间
        demandWorker: null, //管理配员
        ecoAddress: null, //地址
        ecoCoordinateScope: null, //垃圾屋位置坐标
        ecoImageUrls: null, //附件的fileKey
        ecoName: null, //环保垃圾屋名称
        ecoType: null, //垃圾屋类型 0-固定、1-移动
        electricityReady: 0, //是否通电 0-否、1-是
        garbageCompactorNum: null, //压缩垃圾箱数量
        isDrawn: 0, //是否绘制 0-未绘制（默认）、1-已绘制
        propertyRights: null, //产权所有 0-政府投入、1-企业自有
        sewageSituation: null, //排污情况
        waterReady: 0 //是否通水 0-否、1-是
      },
      rules: {
        ecoName: [
          {
            min: 1,
            max: 20,
            required: true,
            message: "请输入1-20位环保垃圾屋名称",
            trigger: "blur"
          },
          { validator: this.$validator.isContainBlank }
        ],
        bidId: {
          required: true,
          message: "请选择",
          trigger: "change"
        },
        propertyRights: {
          required: true,
          message: "请选择",
          trigger: "change"
        }
      }
    };
  },

  methods: {    
    /**
     * @summary【钩子函数，函数名固定】
     * @description 提交表单。此函数内，只需要专注操作表单数据this.form，不用管任何UI逻辑。
     * @author mabo
     */
    async submit() {
      let self = this;
      //提交form数据
      await this.$api_hw.greenGarbageHouse_insert({
        sanitationEcoHouseVO: _fixData(self.form)
      });
      //修剪form的数据
      function _fixData(d) {
        for (let key in d) {
          switch (key) {
            case "ecoType":
            case "electricityReady":
            case "waterReady":
              d[key] = d[key] * 1;
              break;
          }
        }
        //补字段
        if(self.mapData){
          if(self.mapData.lnglat)
            d["ecoCoordinateScope"] = JSON.stringify(self.mapData.lnglat);
          else
            d["ecoCoordinateScope"]="";
          d["isDrawn"]=self.mapData.lnglat?1:0;
        }
        self.log(d);
        return d;
      }
    }    
  },
};
</script>

<style lang='sass' scoped>
.clearBox
  .content-box
    .content-main
      display: inline-block
      width: 100%
      &-left
        float: left
        width: 808px
      &-right
        float: left
        height: 777px
        width: calc(100% - 808px)
        background: #EDEDED
  .order
    overflow-x: visible !important
    &-type
      display: inline-block
      padding-right: 14px
      font-size: 14px
      font-family: Microsoft YaHei
      font-weight: 400
      color: rgba(102,102,102,1)
  .title
    width: 64px
    height: 17px
    font-size: 16px
    font-family: Microsoft YaHei
    font-weight: 400
    color: $-color-primary-3
    line-height: 18px
  .el-checkbox-group
    display: inline-block

  /deep/ .el-radio__input.is-checked .el-radio__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-radio__input.is-checked + .el-radio__label
    color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner
    background: $-color-primary-3
    border-color: $-color-primary-3
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label
    color: $-color-primary-3
  /deep/ .el-form-item
    width: 48%
  /deep/ .el-input--mini
    width: 170px
  .el-date-editor
    width: 170px
  .el-date-editor-bigwidth
    width: 350px
  .el-input-bigwidth
    width: 350px

.add-item
  width: 100%
  // height: 220px
  border: 1px solid rgba(225,225,225,1)
  border-radius: 2px
  margin-bottom: 20px
  .add-item-main
    margin: 10px 0

.add-btn
  margin: 20px 0
  text-align: center
  .btn
    width: 64px
    height: 28px
    border: 1px solid rgba(26,188,156,1)
    border-radius: 4px
    color: #1ABC9C
</style>
<style scoped>
.sewageSituation {
  width: 100% !important;
}
.sewageSituation .el-input--mini {
  width: 320px;
}
.ecoAddress {
  width: 100% !important;
}
.ecoAddress .el-input--mini {
  width: 320px;
}
</style>